/* Style for the settings container */
.settings-container {
  width: 300px; /* Adjust the width as needed */
  margin: 0 auto;
  padding: 5vh;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

/* Style for the settings header */
.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.settings-header img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.settings-header h3 {
  font-size: 20px;
  font-weight: bold;
}

/* Style for input containers */
.settings-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.settings-input-container label {
  width: 20vh; /* Adjust the label width as needed */
  font-weight: bold;
}

.settings-counter {
  display: flex;
  align-items: center;
}

.settings-counter button {
  width: 30px;
  height: 30px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.settings-counter input {
  width: 10vh;
  height: 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 5px;
  font-size: 14px;
}

/* Style for the "Ekle" button */
.add-button {
  align-items: right;
  width: 100px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
/* Style for the "Ekle" button */
.set-button {
  align-items: right;
  width: 100px;
  height: 40px;
  background-color: #2dcd30;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Style for the "Ekle" button */
.reset-button {
  align-items: right;
  width: 100px;
  height: 40px;
  background-color: #808284;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}


.set-button:hover {
  background-color: rgb(2, 158, 7);
}
.reset-button:hover {
  background-color: #626364;
}

.settings-button-container{
  margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: right;
}

@media screen and (max-width:1200px) {
  .popup-content {
    /* Adjust the styles as needed for mobile layout */
    padding: 5px;
  }
  .settings-input-container label {
    width: 1vh; /* Adjust the label width as needed */
    font-size: 10px;
  }
  .add-button {
    width: 50px;
    height: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
  }
  .settings-counter input {
    width: 10vh;
    height: 10px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 0 5px;
    font-size: 14px;
  }
 
  .settings-counter {
    display: flex;
    align-items: center;
  }

  .settings-counter button {
    width: 15px;
    height: 15px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }
  .settings-container {
    width: 500px; /* Adjust the width as needed */
    margin: 0 auto;
    padding: 1vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  /* Style for the settings header */
  .settings-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .settings-header img {
    display: none;
  }
  
  .settings-header h3 {
    font-size: 10px;
    font-weight: bold;
  }
  
  /* Style for input containers */
  .settings-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .set-button {
    align-items: right;
    width: 80px;
    height: 30px;
    background-color: #2dcd30;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
  }
  
  /* Style for the "Ekle" button */
  .reset-button {
    align-items: right;
    width: 80px;
    height: 30px;
    background-color: #808284;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
  }
  /* You can add more specific styles for mobile here */
}