/* Default styles for all page sizes */
.container {
  padding: 40px 10vh;
}


.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
}

.header {
  display: flex;
  align-items: center;
}

.signInHeader {
  margin-bottom: 2vh;
}

.space {
  width: max-content;
}

.csv_buton,
.delete_all_button,
#logout_button {
  padding: 10px 10px;
  margin-left: 1em;
  font-size: 0.6em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.csv_buton {
  background-color: #4CAF50;
  color: #fff;
}

.delete_all_button {
  background-color: #fa0011;
  color: #fff;
}

.csv_buton:hover {
  background-color: #4a7d4c;
}

.delete_all_button:hover {
  background-color: #c12222;
}

#logout_button {
  background-color: #807d7d;
  color: #fff;
  margin-left: 112vh;
}

#logout_button:hover {
  background-color: #151414;
  margin-left: 20px;
}

span {
  font-weight: 400;
  font-size: large;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header */
table thead {
  align-items: center;
  background-color: #f5f5f5;
}

table th {
  padding: 12px;
  text-align: center;
  font-weight: bold;
}

/* Table Body */
table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

table td {
  padding: 10px;
}

/* Table Responsive Styling */
@media (max-width: 767px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  table thead,
  table tbody,
  table th,
  table td,
  table tr {
    display: block;
  }

  table th {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  table tbody tr {
    border-bottom: 1px solid #ddd;
  }

  table td {
    text-align: center;
    border-bottom: none;
    border-right: 1px solid #ddd;
    padding: 6px 12px;
    min-width: 150px;
  }

  table tbody tr:last-child td {
    border-bottom: 1px solid #ddd;
  }
}

/* Responsive styles for small screens */
@media (max-width: 1000px) {
  .container {
    padding: 1px;
  }
}

.auth-container{
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* styles.css (or any preferred filename) */

/* Pagination container */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Adjust margin as needed */
}

/* Pagination icons */
.pagination-icon {
  cursor: pointer;
  color: #fff;
  margin: 0 10px; /* Adjust margin as needed */
  transition: background-color 0.3s ease;
}

/* Disabled pagination icon style */
.pagination-icon[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Pagination span */
.pagination-number {
  font-size: 1rem; /* Adjust font size as needed */
  color: #000000;
  margin: 0 10px; /* Adjust margin as needed */
}
