/* SmallWindow.css */
.small-window-container {
  position: relative;
}

.toggle-button {
  margin-bottom: 2vh;
  cursor: pointer;
}

.note-icon{
  width:auto;
  height:40px;
  cursor:pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  width: 90%;
  max-width: 45vh; /* Adjusted max-width for responsiveness */
  padding: 2.5vh;
  border-radius: 1vh;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 1vh; /* Adjusted top position for responsiveness */
  right: 2vh; /* Adjusted right position for responsiveness */
  font-size: 2.5vh; /* Adjusted font-size for responsiveness */
  cursor: pointer;
}

h3 {
  margin-left: 1vh;
}

.header {
  margin-bottom: 2vh;
}

/* Basic styling */
.adding-button,
.delete-button {
  margin-top: 2vh;
  padding: 1vh 2vh; /* Adjusted padding for responsiveness */
  border-radius: 1vh; /* Adjusted border-radius for responsiveness */
  cursor: pointer;
}

.adding-button{
  background-color: #1eb935;
  color: #fff;
  margin-right: 3vh;
}

.delete-button {
  background-color: #fc4f4f;
  color: #fff;
  margin-left: 3vh;
}

/* Hover effect */
.adding-button:hover {
  background-color: #217d05;
}

.delete-button:hover {
  background-color: #d82222;
}

/* Active effect */
.adding-button:active,
.delete-button:active {
  background-color: #217d05;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space {
  width: 100%;
}

/* Responsive adjustments for screens less than 600px wide (typical mobile phones) */
@media (max-width: 900px) {
  .popup-content {
    max-width: 30%; /* Adjusted max-width for small screens */
    padding: 5vw; /* Adjusted padding for small screens */
  }

  .close-button {
    top: 2vh; /* Adjusted top position for small screens */
    right: 2vh; /* Adjusted right position for small screens */
    font-size: 4vh; /* Adjusted font-size for small screens */
  }


h3 {
  margin-left: 0.5vh;
  font-size: small;
}
p{
  font-size: 0.2px;
}
.header {
  margin-bottom: 1vh;
}
.note-icon{
  height: 20px;
}
}
