/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.rotate-screen{
  display: none;
}

#patient-title{
  font-size:1.5em;
}

.space {
  width: max-content;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-title {
  font-size: 0.9em;
  font-weight: 600;
  margin-left:10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -1.0vh;
  right: 0;
  bottom: 0;
  background-color: #70c972;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.8s;
  border-radius: 50%;
}


input:checked + .slider:before {
  transform: translateX(1.25em); /* Move the circle to the right when locked */
}

.slider.unlocked:before {
  transform: translateX(0); /* Move the circle to the left when unlocked */
}


input:checked + .slider {
  background-color: #ed6c6c; /* Green background when locked */
}

.slider.unlocked:before {
  transform: translateX(0); /* Move the circle to the left when unlocked */
}

input:checked + .slider.unlocked {
  background-color: #ed6c6c; /* Red background when unlocked */
}


.table-container {
  overflow-x: auto;
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  margin-bottom: 200px;
}

th, td {
  font-size: 0.7rem;
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  border: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.dialog-trigger {
  position: relative;
  cursor: pointer;
}

/* Style for the dialog box */
.dialog-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: full; /* Adjust the desired width as needed */
  padding: 10px;
  background-color: rgba(234, 233, 233, 0.9);
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  font-size: 10px; /* Adjust font size as needed */
  text-align: left;
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  height: max-content;
  width: 100%;
}

/* Style for the dialog box */
#dialog-barcode {
  top:0;
  left: 100%;
  width:fit-content;
  min-height: 100px;
}


#dialog-note {
  bottom: 100%;
  left: 100%;
  width: 300px;
  min-height: 100px;
  
}

/* Show the dialog box when hovering over the trigger cell */
.dialog-trigger:hover .dialog-box {
  display: block;
}

.column {
  font-weight: 600;
  font-size: 0.8rem;
  background-color: rgb(166, 165, 165);
  color: white;
}

.empty-cell {
  color: gray;
}

.popup-button {
  display: inline-block;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: #fff;
  font-size: 0.875rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #12801f;
}


.toggles {
  margin-top:10vh;
  padding-top: 10px;

}

h4 {
  font-size: 1.2rem;
}

.toggle-switch {
  position: relative;
  display: flex;
  width: 4vh;
  height: 25px;
  margin: 0 10px;
}

.toggle-container {
  padding: 0.5vh;
  align-items: center;
  display: flex;
}


.toggle-button {
  display: flex;
}

.excel-div {

  display: flex;
  align-items: center;
  justify-content: right;
  width: 20%;
}

.csv_button{
  height:min-content;
  padding: 1em;
  background-color: #4CAF50;
  color: #fff;
  font-size: 0.8em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1em;
}

.upper-container{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}
.togglebars{
  width:max-content;
  justify-content: center;
  align-items: center;
}
.title{
  font-size: 1.5em;
  width: 33%;
}

.bigger-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
}

.user-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vh 0;
 
}



FontAwesomeIcon{
  cursor: pointer;
}

.submit-button {
  margin-left: 2vh;
  margin-right: 2vh;
}

textarea {
  border-radius: 1vh;
  padding: 0.4vh;
}

/* Mobile responsive styles */
@media screen and (min-width: 2101px )  {
  .toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 5;
  left: -1vh;
  right: 0;
  bottom: 0;
  background-color: #70c972;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.8s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ed6c6c; /* Green background when locked */
}

input:checked + .slider:before {
  transform: translateX(1.25em); /* Move the circle to the right when locked */
}

.slider.unlocked:before {
  transform: translateX(0); /* Move the circle to the left when unlocked */
}

input:checked + .slider.unlocked {
  background-color: #ed6c6c; /* Red background when unlocked */
}

  
}

.lock{
  width:auto;
  height:30px;
  cursor:pointer;
}

/* Mobile responsive styles */
@media screen and (min-width: 1801px ) and (max-width: 2100px )  {
  .toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 5;
  left: -2vh;
  right: -1vh;
  bottom: 0;
  background-color: #70c972;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.8s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ed6c6c; /* Green background when locked */
}

input:checked + .slider:before {
  transform: translateX(1.25em); /* Move the circle to the right when locked */
}

.slider.unlocked:before {
  transform: translateX(0); /* Move the circle to the left when unlocked */
}

input:checked + .slider.unlocked {
  background-color: #ed6c6c; /* Red background when unlocked */
}

  
}

/* Mobile responsive styles */
@media screen and (min-width: 1601px ) and (max-width: 1800px ) {


  .toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 5;
  left: -4vh;
  right: -1vh;
  bottom: 0;
  background-color: #70c972;
  transition: 0.4s;
  border-radius: 25px;
  margin-right: 6px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.8s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ed6c6c; /* Green background when locked */
}

input:checked + .slider:before {
  transform: translateX(3vh); /* Move the circle to the right when locked */
}

.slider.unlocked:before {
  transform: translateX(0); /* Move the circle to the left when unlocked */
}

input:checked + .slider.unlocked {
  background-color: #ed6c6c; /* Red background when unlocked */
}

.csv_button{
  height:min-content;
  padding: 1vh;
  background-color: #4CAF50;
  color: #fff;
  font-size: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

}

@media screen and (min-width: 1401px ) and (max-width: 1600px ) {

  .slider {
    position: absolute;
    cursor: pointer;
    top: 5;
    left: -1vh;
    right: 0;
    bottom: 0;
    background-color: #70c972;
    transition: 0.4s;
    border-radius: 25px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.8s;
    border-radius: 50%;
  }
  .bigger-container{
    margin-bottom: 3vh;
  }
  .toggles {
    margin-top: 8vh; /* Adjusted margin for mobile */
    padding-top: 10px; /* Adjusted padding for mobile */
    align-items: left; /* Center-align for mobile */
}

.toggle-switch {
    width: 60px; /* Adjusted width for mobile */
    height: 25px;
    margin: 0 5px; /* Reduced margin for mobile */
}



.title-div {
  display: flex;
  justify-content: left;
  width: 30%; /* Adjusted width for mobile */
}

input:checked + .slider:before {
  transform: translateX(1.2em); /* Move the circle to the right when locked */
  .csv_button{
    height:min-content;
    padding: 1vh;
    background-color: #4CAF50;
    color: #fff;
    font-size: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 2vh;
  }
  
}
}

/* Mobifle responsive styles */
@media screen and (min-width: 1000px ) and (max-width: 1400px ) {

  .slider {
    position: absolute;
    cursor: pointer;
    top: 5;
    left: -1vh;
    right: 0;
    bottom: 0;
    background-color: #70c972;
    transition: 0.4s;
    border-radius: 25px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.8s;
    border-radius: 50%;
  }
  
  .toggles {
    margin-top: 1vh; /* Adjusted margin for mobile */
    padding-top: 10px; /* Adjusted padding for mobile */
    align-items: left; /* Center-align for mobile */
}

.upper-container{
  margin-top: 20px;
}
.toggle-switch {
    width: 50px; /* Adjusted width for mobile */
    height: 20px;
    margin: 0 5px; /* Reduced margin for mobile */
}

.toggle-title {
  font-size: small;
  font-weight: 200;
  margin-left:20px;
}


.csv_button {
  display: none !important;
}
input:checked + .slider:before {
  transform: translateX(22px); /* Move the circle to the right when locked */
}

  
  #patient-title{
    font-size:1em;
  }
  /* Style the table */
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  thead th,
  tbody td {
    font-size: 10px; /* Adjust the font size as needed */
    padding:1px;
  }
  .column {
    font-weight: 100;
    font-size: 10px;
    background-color: rgb(166, 165, 165);
    color: white;
    
  }
  tr{
    font-size: 5px;
  }
  th {
    background-color: #f2f2f2;
    font-size: 5px;
  }
  
  
  .dialog-trigger {
    position: relative;
    cursor: pointer;
  }
/* Style for the dialog box */
.dialog-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 300px; /* Adjust the desired width as needed */
  padding: 10px;
  background-color: rgba(234, 233, 233, 0.9);
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  font-size: 8px; /* Adjust font size as needed */
  text-align: left;
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  height: max-content;
}

  .lock{
    width:auto;
    height:25px;
    cursor:pointer;
  }
}

@media screen and (max-width: 599px ) {

.bigger-container {
    display: none;

}
h2{
  font-size: small;
  font-size: 1px;
}

  table {
    display: none;
  }

  .upper-container{
    display: none;
  }
  
  .rotate-screen{
    margin-top: 20px;
    display: flex;
    font-size: xx-large;
  }
}




/* Mobifle responsive styles */
@media screen and (min-width: 600px ) and (max-width: 1000px ) {

  .slider {
    position: absolute;
    cursor: pointer;
    top: 5;
    left: -1vh;
    right: 0;
    bottom: 0;
    background-color: #70c972;
    transition: 0.4s;
    border-radius: 25px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.8s;
    border-radius: 50%;
  }
  
  .toggles {
    margin-top: 1vh; /* Adjusted margin for mobile */
    padding-top: 10px; /* Adjusted padding for mobile */
    align-items: left; /* Center-align for mobile */
}

.upper-container{
  margin-top: 20px;
}
.toggle-switch {
    width: 50px; /* Adjusted width for mobile */
    height: 20px;
    margin: 0 5px; /* Reduced margin for mobile */
}

.toggle-title {
  font-size: small;
  font-weight: 200;
  margin-left:20px;
}


.csv_button {
  display: none !important;
}
input:checked + .slider:before {
  transform: translateX(20px); /* Move the circle to the right when locked */
}

  
  #patient-title{
    font-size:1em;
  }
  /* Style the table */
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  thead th,
  tbody td {
    font-size: 10px; /* Adjust the font size as needed */
    padding:1px;
  }
  .column {
    font-weight: 100;
    font-size: 10px;
    background-color: rgb(166, 165, 165);
    color: white;
    
  }
  tr{
    font-size: 5px;
  }
  th {
    background-color: #f2f2f2;
    font-size: 5px;
  }
  
  
  .dialog-trigger {
    position: relative;
    cursor: pointer;
  }
/* Style for the dialog box */
.dialog-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 300px; /* Adjust the desired width as needed */
  padding: 10px;
  background-color: rgba(234, 233, 233, 0.9);
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  font-size: 8px; /* Adjust font size as needed */
  text-align: left;
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  height: max-content;
}

  .lock{
    width:auto;
    height:25px;
    cursor:pointer;
  }
}

@media screen and (max-width: 599px ) {

.bigger-container {
    display: none;

}
h2{
  font-size: small;
  font-size: 1px;
}

  table {
    display: none;
  }

  .upper-container{
    display: none;
  }
  
  .rotate-screen{
    margin-top: 20px;
    display: flex;
    font-size: xx-large;
  }
}

