/* Reset default margin and padding */
body, h1, p {
    margin: 0;
    padding: 0;
}

/* Basic styling for the header */
header {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}

/* Styling for the main content */
main {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Styling for the footer */
footer {
    margin-top: 50px;
    height: 100px;
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: x-large;
}

/* Add more styling as needed */
