* {
    box-sizing: border-box;
}

body {
    margin: 0;
}


.nav {
    width: 100%;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 3rem;
    height: 120px;
    align-items: center;
}

.header-container {
  display:flex;
  justify-content: center;
  align-items: center;
}
.space {
  width: max-content;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.site-title {
    margin-left: 3vh;
    font-size: xx-large;
}

.nav ul {
    padding:0;
    margin: 0;
    list-style: none;
    display: flex;
    gap:1rem;
    font-size: 20px;
    padding: 3px;
}

.nav li {
  padding: 3px;
}

.nav li:hover {
    background-color: #777;
    border-radius: 10px;
    padding: 3px;
}

.nav li.active{
    background-color: #555;
}


span {
    font-size: x-large;
    font-weight: 300;
    margin-left: 15px;
}

.logoutIcon{
  cursor: pointer;
}

.auth-container {
    width: 500px;
    margin: 0 auto;
    margin-top: 120px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 20px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
.auth-button {
    width: 80%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .auth-button:hover {
    background-color: #7cc37f;
    margin-bottom: 20px;
  }

  .userName{
    color: white;
  }

  .user-info {
    justify-content: center;
    align-items: center;
  }
  .log-icon {
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
  }

  /* Mobile responsive styles */
@media screen and (max-width: 1200px) {

  .nav {
    width: 100%;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0.5rem;
    padding: 0 0.5rem;
    height: 20vh;
    align-items: center;
}


.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .05rem;
}

.site-title {
    padding:5vh;
    font-size: 16px;
}
span{
  font-size: 13px;  
}
.nav ul {
    padding:0;
    margin: 0;
    list-style: none;
    display: flex;
    gap:1rem;
    font-size: 10px;
    padding: 0.1px;
}

.nav li {
  padding: 0.1px;
}

.nav li:hover {
    background-color: #777;
    border-radius: 10px;
    padding: 3px;
}

.nav li.active{
    background-color: #555;
}
.logoutIcon{
  cursor: pointer;
  height: 4vh;
}
.userName{
  color: white;
  font-size: 10px;
}

}

#dashboard-icon {
  margin-left: 4vh;
}

.buttons-div{
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
}